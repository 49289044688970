<template>
  <component :is="contentComponent" />
</template>

<script>

const _m = () => import('@/pages/meaning/m.vue') //代码切割
const _pc = () => import('@/pages/meaning/pc.vue') //代码切割
import { mapGetters } from 'vuex';

export default {
  data () {
    return {
      contentComponent: null,
    };
  },
  metaInfo () {
    return {
      title: 'Tarot meaning | Daily Tarot - Your Free Daily Tarot Card Readings and Insights',
      meta: [
        {
          name: 'description',
          content: 'Discover your daily tarot card readings and gain insights into your life, love, career, and more. Visit Daily Tarot for free tarot readings, horoscopes, and personalized guidance.',
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'deviceType',
    ])
  },
  created () {
    console.log(`deviceType - ${this.deviceType}`);
    if (this.deviceType == 'desktop' || this.deviceType == 'pc') {
      this.contentComponent = _pc
      return
    }
    this.contentComponent = _m
  },
};
</script>
